import React, { ReactElement, ReactNode } from 'react';
import {
  chakra, Box, Icon, Flex, Stack,
} from '@chakra-ui/react';

function Feature(props: { icon: ReactElement;
  title: string;
  iconSize?: number;
  children: ReactNode }) {
  return (
    <Flex>
      <Flex shrink={0}>
        <Flex
          alignItems="center"
          justifyContent="center"
          h={12}
          w={12}
          rounded="md"
          _light={{
            bg: '#00805e',
          }}
          color="white"
        >
          <Icon
            boxSize={6}
            fill="none"
            viewBox={`0 0 ${props.iconSize || 24} ${props.iconSize || 24}`}
            stroke="currentColor"
            aria-hidden="true"
          >
            {props.icon}
          </Icon>
        </Flex>
      </Flex>
      <Box ml={4}>
        <dl>
          <chakra.dt
            fontSize="lg"
            fontWeight="medium"
            lineHeight="6"
            _light={{
              color: 'gray.900',
            }}
          >
            {props.title}
          </chakra.dt>
          <chakra.dd
            mt={2}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
          >
            {props.children}
          </chakra.dd>
        </dl>
      </Box>
    </Flex>
  );
}

Feature.defaultProps = {
  iconSize: 24,
};

export default function FeatureThree() {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      px={{
        lg: 20,
      }}
      pt={{
        base: 0,
        lg: 10,
      }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        bg="white"
        id="content"
        _dark={{
          bg: 'gray.800',
        }}
        borderTopRadius={{
          base: '0px',
          lg: '25px',
        }}
        shadow="xl"
        padding={{
          base: 5,
          md: '3rem',
          lg: 20,
        }}
        w={{
          '2xl': '75%',
        }}
      >
        <Box
          textAlign={{
            lg: 'center',
          }}
        >
          <chakra.p
            mt={2}
            fontSize={{
              base: '3xl',
              sm: '4xl',
            }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            _light={{
              color: 'gray.900',
            }}
          >
            Vain yksi klikkaus ja olet kirjautunut.
          </chakra.p>
          <chakra.p
            mt={4}
            maxW="5xl"
            fontSize="xl"
            mx={{
              lg: 'auto',
            }}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
          >
            Sen sijaan että oppilaiden tarvitsee tuhlata aikaa palvelun kirjautumissivun etsimiseen
            sekä käyttäjänimien ja salasanojen muistamiseen, kirjautuminen hoituu yhdellä
            klikkauksella selaimen etusivulta.
          </chakra.p>
        </Box>

        <Box mt={10}>
          <Stack
            spacing={{
              base: 10,
              md: 0,
            }}
            display={{
              md: 'grid',
            }}
            gridTemplateColumns={{
              md: 'repeat(2,1fr)',
            }}
            gridColumnGap={{
              md: 8,
            }}
            gridRowGap={{
              md: 10,
            }}
          >
            <Feature
              title="Yksi klikkaus, ei muuta"
              icon={(
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                />
                )}
            >
              Yhdellä klikkauksella pääset suoraan palveluun tai vähintään kirjautumissivulle
              integraation puuttuessa. Niin helppoa se on.
            </Feature>
            <Feature
              title="Parempi tietoturva"
              icon={(
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
                )}
            >
              Pulpetin käytön avulla voidaan estää mm.
              {' '}
              <abbr title="SEO Poisoning">&quot;hakutulosten myrkytys&quot;</abbr>
              -hyökkäyksen sekä vähentää mahdollisuutta phishing-hyökkäyksiin.
            </Feature>

            <Feature
              title="Ei pelkkä portaali"
              icon={(
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                />
                )}
            >
              Voit sisällyttää Pulpetti-etusivulle esimerkiksi ruokalistan tai muuta tietoa.
              Voit myös muokata sivuston värejä kuntasi tai oppilaitoksesi brändin mukaiseksi.
            </Feature>

            <Feature
              title="Tuemme useita käyttäjärekistereitä"
              icon={(
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
                )}
            >
              Pulpetti on ainut saatavilla oleva opetuksen verkkotyöpöytä,
              joka tukee useaa eri käyttäjärekisteriä. Voit käyttää useaa rekisteriä yhtä aikaa,
              esimerkiksi perusopetuksen oppilaat kirjautuvat Opinsysillä ja lukiolaiset
              Microsoft 365:n avulla!
            </Feature>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
}
