import {
  Box,
  Button,
  Flex,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { GiSpeedometer } from '@react-icons/all-files/gi/GiSpeedometer';
import { RiLeafLine } from '@react-icons/all-files/ri/RiLeafLine';
import { FiGlobe } from '@react-icons/all-files/fi/FiGlobe';
import { BiServer } from '@react-icons/all-files/bi/BiServer';
import { Link as GatsbyLink } from 'gatsby';

function Feature(
  {
    children,
    title,
    iconOverride,
  }: { children: any; title: string; iconOverride?: ReactElement<any, any> },
) {
  return (
    <Flex>
      <Flex shrink={0}>
        { iconOverride || (
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color="brand.500"
            _dark={{
              color: 'brand.300',
            }}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </Icon>
        )}
      </Flex>
      <Box ml={4}>
        <Heading
          as="h3"
          fontSize="lg"
          fontWeight="bold"
          lineHeight="6"
          _light={{
            color: 'gray.900',
          }}
        >
          {title}
        </Heading>
        <Text
          mt={2}
          color="#4E576A"
          _dark={{
            color: 'gray.400',
          }}
        >
          {children}
        </Text>
      </Box>
    </Flex>
  );
}

Feature.defaultProps = {
  iconOverride: null,
};

function FeatureFour() {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      px={{
        lg: 20,
      }}
      pb={{
        base: 0,
        lg: 10,
      }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        shadow={{
          lg: 'xl',
        }}
        bg="white"
        _dark={{
          bg: 'gray.800',
        }}
        padding={{
          base: 5,
          md: '3rem',
          lg: 20,
        }}
        mx="auto"
        w={{
          '2xl': '75%',
        }}
        borderBottomRadius={{
          lg: '25px',
        }}
      >
        <SimpleGrid
          w="auto"
          alignItems="center"
          columns={{
            base: 1,
            lg: 3,
          }}
          spacingY={{
            base: 5,
            lg: 32,
          }}
          spacingX={{
            base: 0,
            lg: 24,
            '2xl': 12,
          }}
        >
          <Box alignSelf="start">
            <Heading
              as="h2"
              mb={3}
              fontSize={{
                base: '3xl',
                md: '4xl',
              }}
              fontWeight="extrabold"
              textAlign={{
                base: 'center',
                sm: 'left',
              }}
              _light={{
                color: 'green.900',
              }}
              lineHeight="shorter"
              letterSpacing="tight"
            >
              Huoletta pilvipalveluna
            </Heading>
            <Text
              mb={6}
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              textAlign={{
                base: 'center',
                sm: 'left',
              }}
              color="gray.600"
              _dark={{
                color: 'gray.500',
              }}
            >
              Ritta Pulpetti tarjotaan helppokäyttöisenä pilvipalveluna.
            </Text>
            <Button
              as={GatsbyLink}
              to="/ota-yhteytta"
              variant="solid"
              w={{
                base: 'full',
                sm: 'auto',
              }}
              colorScheme="accesibleButton"
              _hover={
                {
                  backgroundColor: '#00916a',
                }
              }
              color="white"
              size="lg"
            >
              Kysyttävää? Ota yhteyttä!
            </Button>
          </Box>
          <GridItem colSpan={2}>
            <Stack
              spacing={{
                base: 10,
                md: 0,
              }}
              display={{
                md: 'grid',
              }}
              gridTemplateColumns={{
                md: 'repeat(2,1fr)',
              }}
              gridColumnGap={{
                md: 8,
              }}
              gridRowGap={{
                md: 10,
              }}
            >
              <Feature
                title="Ei hidasteluja"
                iconOverride={(
                  <GiSpeedometer size={20} style={{ marginRight: '0.5rem', marginTop: '0.25rem' }} />
              )}
              >
                Pulpetti käyttää Suomessa sijaitsevia palvelimia
                sekä nykyaikaisia tehokkaita teknologioita.
                {' '}
              </Feature>
              <Feature
                title="100% vihreää energiaa = 0 CO₂"
                iconOverride={(
                  <RiLeafLine
                    size={20}
                    style={{ marginRight: '0.5rem', marginTop: '0.25rem' }}
                    color="#006634"
                  />
              )}
              >
                Pulpetin pilvipalvelu pyörii 100% vihreällä energialla pyörivillä palvelimilla
                ilman hiilidioksidipäästöjä.
              </Feature>
              <Feature
                title="Integraatiot ja linkit helposti"
                iconOverride={(
                  <FiGlobe size={20} style={{ marginRight: '0.5rem', marginTop: '0.25rem' }} />
              )}
              >
                {' '}
                Pilvipalvelussa lisäät eri palvelut ja muut linkit helposti hallintapaneelista
                haluamillesi käyttäjäryhmille.
              </Feature>
              <Feature
                title="Hoidamme tietoturvan ja tekniikan"
                iconOverride={(
                  <BiServer size={20} style={{ marginRight: '0.5rem', marginTop: '0.25rem' }} />
              )}
              >
                {' '}
                Hoidamme puolestanne päivitykset, varmuuskopiot, tietoturvan, palvelimet
                ja lähes kaiken muun teknisen puolestanne. Järjestelmät päivitetään ja
                varmuuskopioidaan päivittäin.
              </Feature>
            </Stack>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}

export default FeatureFour;
