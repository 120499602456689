import { graphql } from 'gatsby';
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import AppTemplate from '../components/AppTemplate';
import CTA from '../components/PulpettiSections/CTA';
import FeatureFour from '../components/PulpettiSections/FeatureFour';
import FeatureSix from '../components/PulpettiSections/FeatureSix';
import Hero from '../components/PulpettiSections/Hero';
import ShowOnBreakPoint from '../components/ShowOnBreakpoint';

// markup
function IndexPage({ data }: any) {
  return (
    <AppTemplate
      navigation={data.contentfulNavigation}
      SEO={{
        title: 'Pulpetti - Helppokäyttöinen oppimisen verkkotyöpöytä',
        description: 'Pulpetti on helppokäyttöinen, nopea ja nykyaikainen oppimisen digitaalinen verkkotyöpöytä nopeuttamaan opetusta helposti pilvipalveluna.',
        author: 'Ritta.fi',
        pathname: '/pulpetti',
        imageUrl: data.contentfulLandingPage.heroImage.file.url,
      }}
    >
      <Hero data={data.contentfulLandingPage} />
      <ShowOnBreakPoint breakpoint="lg">
        <Box
          sx={{
            background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,243,248,1) 100%)',
            height: '300px',
            width: '100%',
          }}
          pos="relative"
          zIndex={-1}
          top={-200}
          marginBottom={-200}
        />
      </ShowOnBreakPoint>
      <FeatureFour />
      <FeatureSix />
      <ShowOnBreakPoint breakpoint="lg" min>
        <Box
          sx={{
            background: 'linear-gradient(0deg, var(--chakra-colors-gray-100) 0%, #fff 100%)',
            height: '45px',
            width: '100%',
          }}
        />
      </ShowOnBreakPoint>
      <CTA />
      <Box
        sx={{
          background: 'linear-gradient(180deg, #fff 0%, var(--chakra-colors-gray-50) 100%)',
          height: '45px',
          width: '100%',
        }}
      />
    </AppTemplate>
  );
}

export const query = graphql`
  query pulpettiQuery($locale: String) {
    contentfulLandingPage(
      contentful_id: {eq: "6QiJ1HJLc4ZE7CHGDvaZeF"}
      node_locale: { eq: $locale }
    ) {
      heroTitle {
        raw
      }
      heroSubtitle {
        raw
      }
      heroImage {
        file {
  
        url}
      }
    }

    contentfulNavigation(
      contentful_id: {eq: "39n107RjyAszi5xLee3gMS"}
      node_locale: {eq: $locale}
    ) {
      title
      navigationOptions {
        ... on ContentfulNavigationLink {
          title
          url
          __typename
        }
 
      }
      loginText
    }
  }
`;

export default IndexPage;
