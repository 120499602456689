import { ReactNode } from 'react';
import {
  Box, Button, Center, chakra, Flex, Icon, SimpleGrid, Stack, Text, useColorModeValue,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

function Feature(props: { children: ReactNode }) {
  return (
    <Flex align="center">
      <Flex shrink={0}>
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color="brand.500"
          _dark={{
            color: 'brand.300',
          }}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </Icon>
      </Flex>
      <Box ml={4}>
        <chakra.span
          mt={2}
          color="gray.600"
          _dark={{
            color: 'gray.400',
          }}
        >
          {props.children}
        </chakra.span>
      </Box>
    </Flex>
  );
}

export default function CTA() {
  const topBg = useColorModeValue('gray.100', 'gray.700');
  const bottomBg = useColorModeValue('white', 'gray.800');

  return (
    <Flex
      boxSize="full"
      bg="#F9FAFB"
      _dark={{
        bg: 'gray.600',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        mx="auto"
        textAlign={{
          base: 'left',
          md: 'center',
        }}
        rounded="md"
        w="full"
        bg={bottomBg}
      >
        <Box
          pt={{ base: 5, '2xl': 20 }}
          rounded="md"
          bg={topBg}
        >
          <Box w="full" px={[10, null, 4]} mx="auto">
            <Text
              mb={2}
              fontSize={{
                base: '2xl',
                lg: '5xl',
              }}
              fontWeight="bold"
              lineHeight="tight"
            >
              Tule mukaan pilotointiin!
            </Text>
            <chakra.p
              mb={6}
              fontSize={['lg', null, 'xl']}
              color="gray.600"
              _dark={{
                color: 'gray.400',
              }}
            >
              Etsimme yhteistyökumppaneita Pulpetin pilotointiin.
              <br />
              Pilotoinnin aikana viimeistelemme
              ratkaisua oppilaitosten tarpeiden ja järjestelmien kanssa yhteensopiviksi.
            </chakra.p>
          </Box>
          <Box bgGradient={`linear(to-b, ${topBg} 50%, ${bottomBg} 50%)`}>
            <Center>
              <Flex
                rounded="md"
                mx={10}
                bg={bottomBg}
                shadow="xl"
                mb="100px"
                textAlign="left"
                direction={{
                  base: 'column',
                  lg: 'row',
                }}
                w={{
                  base: '100%',
                  md: '70%',
                }}
              >
                <Stack spacing={8} p="45px" flex="0.7">
                  <Text fontSize="2xl" fontWeight="bold" lineHeight="tight">
                    Pulpetti pilvipalveluna
                  </Text>
                  <chakra.p
                    fontSize={['sm', null, 'md']}
                    color="gray.600"
                    _dark={{
                      color: 'gray.400',
                    }}
                  >
                    Pulpetin käyttöönotto on suunniteltu helpoksi kunnalle.
                    Järjestelmä kytketään käyttäjähallintajärjestelmään (Opinsys, Primus),
                    sekä muihin opetuksen järjestelmiin (Google Workspace, MPASSid...).
                    <br />
                    <br />
                    Tämän jälkeen
                    otetaan käyttöön verkkotunnus (esim. pulpetti.kunta.fi), ja järjestelmä
                    on valmis käytettäväksi.
                    <br />
                    <br />
                    <chakra.span fontWeight={600}>
                      Huomio! Pilotointivaiheen aikana käyttöönotot saattavat kestää normaalia
                      pidempään tiettyjen integraatioiden puuttuessa.
                    </chakra.span>
                  </chakra.p>
                  <Flex align="center">
                    <Text
                      fontFamily="body"
                      whiteSpace="nowrap"
                      fontWeight="semibold"
                      textTransform="uppercase"
                      color="brand.400"
                    >
                      Käyttöönottoon sisältyy:
                    </Text>
                    <Flex
                      ml="15px"
                      w="full"
                      borderTopWidth="1px"
                      h="3px"
                      borderTopColor={topBg}
                    />
                  </Flex>
                  <SimpleGrid columns={[1, null, 2, 1, 2]} spacingY={4}>
                    <Feature>Integraatioiden käyttöönotto</Feature>
                    <Feature>Järjestelmän asennus kunnan verkkotunnukseen</Feature>
                    <Feature>Käyttöopas pääkäyttäjälle</Feature>
                  </SimpleGrid>
                </Stack>
                <Stack
                  p="45px"
                  flex="0.3"
                  justify="center"
                  align="center"
                  bg="#F9FAFB"
                  _dark={{
                    bg: 'gray.900',
                  }}
                  borderRightRadius="md"
                >
                  <Flex
                    align="center"
                    fontSize="5xl"
                    fontWeight={['bold', null, 'extrabold']}
                    lineHeight="tight"
                    textAlign="center"
                  >
                    Vain
                    50€/kk
                  </Flex>
                  <chakra.span
                    ml={2}
                    fontSize="lg"
                    fontWeight="medium"
                    textAlign="center"
                    color="gray.600"
                    _dark={{
                      color: 'gray.400',
                    }}
                  >
                    Yht. 600€/v
                    <br />
                    Ei käyttöönotto- tai käyttäjämaksuja.
                  </chakra.span>
                  <Stack spacing={6}>
                    <Text
                      color="black"
                      textAlign="center"
                    >
                      Laskutetaan vuosittain. Hinnat eivät sisällä arvonlisäveroa.
                      <br />
                      Max. 500 käyttäjää. Pyydä tarjous suuremmille oppilasmäärille.
                    </Text>
                    <Button colorScheme="accesibleButton" py={6} as={GatsbyLink} to="/ota-yhteytta?topic=pulpetti">
                      Ota yhteyttä!
                    </Button>
                    <Text align="center" fontWeight="semibold">
                      Erityishinnoittelu ovat saatavilla pilottivaiheen aikana
                      palvelun käyttöönottaville opetuksen järjestäjille.
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
            </Center>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
