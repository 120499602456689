import React from 'react';
import {
  Box,
  Text,
  Button,
  Stack,
  Icon,
  Heading,
  Image,
  Flex,
  Center,
  chakra,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export default function Hero({ data } : { data: any; }) {
  return (
    <Flex
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        _dark={{
          bg: 'gray.800',
        }}
        px={{
          base: 10,
          lg: 16,
          xl: 24,
        }}
        py={20}
        borderBottomRadius={{
          base: '0px',
          lg: '25px',
        }}
        w="full"
      >
        <Box
          w={{
            base: 'full',
            md: 11 / 12,
            xl: 9 / 12,
          }}
          mx="auto"
          textAlign="center"
        >
          <Heading
            as="h1"
            mb={6}
            fontSize={{
              base: '4xl',
              md: '6xl',
            }}
            fontWeight="bold"
            lineHeight="none"
            letterSpacing={{
              base: 'normal',
              md: 'tight',
            }}
            color="gray.900"
            _dark={{
              color: 'gray.100',
            }}
          >
            <Center>
              Yksi klikkaus
              <chakra.div sx={{
                position: 'relative',
              }}
              >
                <chakra.svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  sx={{
                    position: 'absolute',
                    height: '45px',
                    right: '-30px',
                    bottom: '-52px',
                    '@media (max-width: 48em)': {
                      height: '25px',
                      right: '-18px',
                      bottom: '-30px',
                    },
                    '@media (max-width: 303px)': {
                      height: '25px',
                      right: '5px',
                      bottom: '-50px',
                    },
                  }}
                >
                  <path
                    d="M7.904 17.563a1.2 1.2 0 0 0 2.228.308l2.09-3.093 4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047-1.047a1.067 1.067 0 0 0 0-1.509l-4.907-4.907 3.113-2.09a1.2 1.2 0 0 0-.309-2.228L4 4l3.904 13.563z"
                  />
                </chakra.svg>
              </chakra.div>
              <br />
            </Center>
            <chakra.div
              className="separator"
              sx={{
                marginY: '10px',
                '@media (max-width: 48em)': {
                  marginY: '5px',
                },
              }}
            />
            <Text
              display={{
                base: 'block',
                lg: 'inline',
              }}
              w="full"
              bgClip="text"
              bgGradient="linear(to-r, green.400,purple.500)"
              fontWeight="extrabold"
              lineHeight="normal"
            >
              kaikki opetuksen palvelut
              <br />
            </Text>
          </Heading>
          <Text
            px={{
              base: 0,
              lg: 24,
            }}
            mb={6}
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color="gray.600"
            _dark={{
              color: 'gray.300',
            }}
          >
            Pulpetti on helppokäyttöinen, nykyaikainen ja simppeli oppimisen työpöytä.
            {' '}
            <br />
            Oppilaat ja opettajat pääsevät eri opetuksen palveluihin yhdellä klikkauksella.
          </Text>
          <Stack
            direction={{
              base: 'column',
              sm: 'row',
            }}
            mb={{
              base: 4,
              md: 8,
            }}
            spacing={2}
            justifyContent="center"
          >
            <Button
              as="a"
              href="#content"
              variant="solid"
              colorScheme="accesibleButton"
              _hover={
              {
                backgroundColor: '#00916a',
              }
            }
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              size="lg"
              cursor="pointer"
            >
              Lue lisää
              <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </Icon>
            </Button>
            <Button
              as={GatsbyLink}
              to="/ota-yhteytta"
              colorScheme="gray"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              size="lg"
              cursor="pointer"
            >
              Ota yhteyttä
              <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </Icon>
            </Button>
          </Stack>
        </Box>
        <Box
          w={{
            base: 'full',
            md: 10 / 12,
            xl: 7 / 12,
          }}
          sx={{
            aspectRatio: '16 / 9',
          }}
          mx="auto"
          display={{
            base: 'none',
            md: 'inherit',
          }}
          textAlign="center"
        >
          <Image
            w="full"
            h="full"
            rounded="lg"
            shadow="2xl"
            src={`${data.heroImage.file.url}?fm=webp`}
            alt="Pulpetin käyttöliittymästä otettu kuvakaappaus"
            loading="lazy"
          />
        </Box>
      </Box>
    </Flex>
  );
}
